<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:black;">
                        <i class="fas fa-home"></i> Home<span style="color:black"> >></span>
                    </a>

                </li>
                <li class="breadcrumb-item" style="color:black;">{{ getCategoryName(activeCategoryID) }} >></li>
                
            </ul>
        </div>
    </nav><br />
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="vertical-pills">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item" *ngFor="let item of originalCategory">
                            <!-- Display the category name -->
                            <a class="nav-link"
                               [routerLink]="['/Products']"
                               [class.active]="activeCategoryID === item.CategoryID"
                              
                               [queryParams]="{ categoryID: item.CategoryID }"
                              >
                                {{ item.CategoryName }}
                              
                                 </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-9">
              
                <div>
                    <table class="table" *ngIf="isGridView">
                        <!-- Table view content -->
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Catelog_No</th>
                                <th scope="col">Chemical Name</th>
                                <th scope="col">CAS No</th>

                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let product of category">
                                <tr (click)="navigatingToSearchedProduct(product)" style="cursor:pointer">
                                    <td>{{ product.Catalog_No }}</td>
                                    <td>{{ product.ProductName }}</td>
                                    <td>{{ product.CAS_No }}</td>

                                </tr>
                                <tr *ngIf="selectedProduct && selectedProduct.ProductName === product.ProductName">
                                    <td colspan="3">
                                        <table class="table">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th scope="col">Mol Formula</th>
                                                    <th scope="col">Mol Weight</th>
                                                    <th scope="col">Inchi</th>
                                                    <th scope="col">Tax</th>
                                                    <th scope="col">ISO Smiles</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td>{{ selectedProduct.Mol_Formula }}</td>
                                                    <td>{{ selectedProduct.Mol_Weight }}</td>
                                                    <td>{{ selectedProduct.Inchi }}</td>
                                                    <td>{{ selectedProduct.Tax }}</td>
                                                    <td>{{ selectedProduct.ISO_Smiles }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>


                </div>

            </div>
        </div>
    </div>
