import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router'
@Component({
  selector: 'app-distributors-reg',
  templateUrl: './distributors-reg.component.html',
  styleUrls: ['./distributors-reg.component.scss']
})
export class DistributorsRegComponent {
    public form: FormGroup;
    arr: any = [];
    passwordshow: boolean = false;
    Mobilenum: any; checkNo: any;
    mobiledata: any;
    HomeUrl: any;
    token: any;
    Category: any;
    BranchdropList: any;
    enableGSTNoField: any;
    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(public generalservice: GeneralService, public fb: FormBuilder, public http: HttpClient,
        private router: Router) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'


        });

        this.form = this.fb.group({

            UserName: ['', Validators.required],
            EMailID: ['', Validators.required],
            Phone1: ['', Validators.required],
            City: ['', Validators.required],
            PWD: ['', Validators.required],

            GSTNo: ['',],
        })

    }



    ngOnInit() {

    }




    onSubmit() {
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            if (

                !this.form.value.UserName ||
                !this.form.value.EMailID ||
                !this.form.value.Phone1 ||
                !this.form.value.City ||
                !this.form.value.PWD

            ) {
                this.generalservice.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please fill in all fields', '');
                return;
            }

            if (this.checkNo === 10) {
                this.generalservice.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Mobile number already exists.', '');
                return;
            }
            this.arr = [];
            this.arr.push({

                UserName: this.form.value.UserName,
                EMailID: this.form.value.EMailID,
                Phone1: this.form.value.Phone1,
                City: this.form.value.City,
                PWD: this.form.value.PWD,
                GSTNo: this.form.value.GSTNo,
                RoleId:3,
                Status: true

            });

            var uploadfile = new FormData;
            uploadfile.append("Param", JSON.stringify(this.arr));
            uploadfile.append("Flag", "1");

            //var url = "api/NovusChem/NC_Registation_GAUD";

            var url = this.HomeUrl + "api/NovusChem/NC_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, uploadfile, { headers }).subscribe(
                (data: any) => {

                    if (data === "Mobile Exists") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Mobile Number Already Exists', '');
                    } else if (data != "") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Your registration completed successfully', '');
                        localStorage.setItem("LoginDetails", JSON.stringify(this.arr));

                        var uploadfile = new FormData();
                        uploadfile.append("Email", JSON.stringify(this.arr));
                        var url = "api/NovusChem/RegistrationMailTo_Admin";
                        this.generalservice.PostData(url, uploadfile).then(data => {

                            var msg = data;
                            this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Registration completed successfully and mail sent to admin for approval', 'Success');
                            localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                            this.router.navigate(['/']).then(() => {
                                window.location.reload();
                            });
                            this.router.navigate(['/']).then(() => {
                                window.location.reload();
                            });
                        });

                    }

                },
                (err) => {
                    //this.generalservice.ShowAlert(' < img src = "../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong, please try again later', '< img src = "../../../assets/icons/icons8-cancel.gif" />');
                });
        });
    }

    /*check mobile exist or not*/
    CheckMobileNo() {
        debugger
        this.Mobilenum = this.form.get('Phone1').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("Param", '1');
        UploadFile.append("Param1", this.Mobilenum);
        var url = "api/NovusChem/Get_Check_Mobile_Email"
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.mobiledata = data;
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    /*endregion*/


}
