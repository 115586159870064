
        <div id="about" class="about-area pt-100">
            <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;"> 
                <div class="container">
                    <ul style=" color: white;  ">
                        <div style="color:#d03737;">About Us</div>
                        <li style="cursor:pointer;">
                            <a href="/" style="color:#d03737;">
                                <i class="fas fa-home"></i> Home /
                            </a>
                        </li>
                        <li class="breadcrumb-item" style="color:#d03737;">About</li>
                    </ul>
                </div>
            </nav>
            <section class="section_all bg-light" id="about">
                <div class="container">
                    <div class="row vertical_content_manage">
                        <div class="col-lg-12">
                            <h6 style="color:#2c3145">Welcome To NovusChem</h6>
                            <h4>Leading the Way in High-Quality Chemical Solutions and Custom Synthesis</h4>
                        </div>
                        <div class="col-lg-6">
                            <img src="assets/img/home/about.jpeg" class="img-fluid mx-auto d-block imgg"/>
                        </div>
                        <div class="col-lg-6">
                            <div class="about_header_main mt-3 ml-3">
                                <div class="about_icon_box">
                                    <p class="text_custom ">
                                        About Novus ChemStrength
                                    </p>
                                </div>
                                <p class="text-muted mt-3">
                                    <strong>Novus ChemStrength</strong> stands as a premier leader in the supply of high-quality chemicals and life science products.
                                    We are dedicated to empowering industries with innovative solutions through our extensive range of chemical products, custom synthesis, and analytical and laboratory services.
                                </p>

                                <p class="text-muted mt-3">
                                    Our extensive product portfolio features over  <strong>180,000 products</strong>, encompassing both organic and inorganic compounds. This includes essential building blocks such as <strong>heterocycles, boronic acids, amino acids, and chiral compounds</strong>. We also provide a wide array of laboratory chemicals in various grades suitable for applications like <strong>LR, AR, HPLC, LC/MS, and ICP</strong>. Our specialty chemicals include coupling reagents, protecting agents, and nucleosides, all critical for advanced research and development.
                                </p>
                                <!--<p>
                In addition to our comprehensive product range, Novus ChemStrength excels in delivering tailored custom synthesis solutions. From initial process development and scale-up to sourcing complex molecules, we are committed to meeting the unique needs of our clients.

                We primarily serve researchers, scientists, and laboratory technicians who rely on top-tier chemical products and synthesis solutions to drive their research and development efforts. Our dedication to quality, innovation, and exceptional customer service has solidified our position as a global leader in chemical supply.

                Novus ChemStrength continually invests in research and development to expand our offerings and incorporate the latest advancements in technology. This relentless pursuit of excellence ensures that our clients receive the most cutting-edge solutions available, reaffirming our commitment to supporting their groundbreaking work.
            </p>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <p class="text-muted mt-3">
                                In addition to our comprehensive product range, Novus ChemStrength excels in delivering tailored custom synthesis solutions. From initial process development and scale-up to sourcing complex molecules, we are committed to meeting the unique needs of our clients.
                            </p>
                            <p class="text-muted mt-3">
                                We primarily serve researchers, scientists, and laboratory technicians who rely on top-tier chemical products and synthesis solutions to drive their research and development efforts. Our dedication to quality, innovation, and exceptional customer service has solidified our position as a global leader in chemical supply.
                            </p>
                            <p class="text-muted mt-3">
                                Novus ChemStrength continually invests in research and development to expand our offerings and incorporate the latest advancements in technology. This relentless pursuit of excellence ensures that our clients receive the most cutting-edge solutions available, reaffirming our commitment to supporting their groundbreaking work.
                            </p>
                        </div>
                        <div class="col-lg-6">
                            <div class="img_about mt-3">
                                <img src="../../../assets/img/home/bg.jpeg"  class="img-fluid mx-auto d-block imgg">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
