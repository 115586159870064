import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service'
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements AfterViewInit {
    @ViewChild('productContainer') productContainer!: ElementRef<HTMLDivElement>;

    arr: any = [];
    category: any = [];
    ProductsLists: any;
    isScrolling: any;
    LocDet: any;
    token: any;
    dataResult: any;
    HomeUrl: any;


    constructor(public router: Router, public http: HttpClient, private viewportScroller: ViewportScroller, location: Location, private generalservice: GeneralService, ) {

        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngAfterViewInit(): void {
        this.startAutoScroll();
        this.top();
        this.GetHotProduts();
      
    }
    ngOnInit(): void {

        this.GetBanner();

    }
    getCategoryDetails() {
        if (!this.category || this.category.length === 0) {
            return 'No products matched'; // Display this message if no results are found
        }

        let details = '';
        this.category.forEach((item, index) => {
            details += `Product ${index + 1}: ${item.Products}\n`;
        });

        return details;
    }

    navigatingToSearchedProduct(Productname: any) {
       
        this.router.navigate(['/Product/' + Productname]);
    }

    startAutoScroll(): void {
        const container = this.productContainer.nativeElement;
        const scrollDelay = 10; // Adjust the scroll delay as needed (in milliseconds)
        const scrollSpeed = 1; // Adjust the scroll speed as needed

        container.scrollLeft = 0; // Set initial scroll position to the start

        setInterval(() => {
            if (this.isScrolling) {
                container.scrollLeft += scrollSpeed;
                if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
                    container.scrollLeft = 0; // Reset to start when reaching the end
                }
            }
        }, scrollDelay);
    }
    stopScroll() {
        debugger
        this.isScrolling = false;
    }

    startScroll() {
        this.isScrolling = true;
    }

    GetHotProduts() {

        var GetUrl = 'api/NovusChem/NC_Get_HotProducts';
        this.generalservice.GetData(GetUrl).then((data: any) => {
            ;
            debugger
            this.ProductsLists = data;

        });
    }


    navigateToProduct(pro: any) {
        debugger
  
        this.router.navigateByUrl('/Product/:Productdetails');

        localStorage.setItem('ProName', pro.ProductName)
        const url = `${pro.CategoryName}-${pro.CAS_No}-${pro.ProductName}`;
        this.router.navigate(['/Product/', url]).then(() => {
            // Reload the page
            window.location.reload();
        });
    }

   

    loc1() {
        ;
        var GetUrl = 'http://api.ipstack.com/check?access_key=83ef7a5415d0c9522944fa09208ac349';

        //this.http.get(GetUrl).pipe(map(res => res.json())).subscribe((data) => {

        this.http.get(GetUrl).subscribe((response: any) => {



            this.LocDet = response;
            // let user = response.json();
            //this.Location = response;


        });

    }



    top() {
        (function () {
            'use strict';
            if ('querySelector' in document && 'addEventListener' in window && Array.prototype.forEach) {
                var smoothScroll = function (anchor, duration) {
                    var startLocation = window.pageYOffset;
                    var endLocation = anchor.offsetTop;
                    var distance = endLocation - startLocation;
                    var increments = distance / (duration / 16);
                    var stopAnimation;
                    var animateScroll = function () {
                        window.scrollBy(0, increments);
                        stopAnimation();
                    };

                    if (increments >= 0) {
                        stopAnimation = function () {
                            var travelled = window.pageYOffset;
                            if ((travelled >= (endLocation - increments)) || ((window.innerHeight + travelled) >= document.body.offsetHeight)) {
                                clearInterval(runAnimation);
                            }
                        };
                    }
                    // If scrolling up
                    else {
                        // Stop animation when you reach the anchor OR the top of the page
                        stopAnimation = function () {
                            var travelled = window.pageYOffset;
                            if (travelled <= (endLocation || 0)) {
                                clearInterval(runAnimation);
                            }
                        };
                    }

                    // Loop the animation function
                    var runAnimation = setInterval(animateScroll, 16);

                };

                // Define smooth scroll links
                var scrollToggle = document.querySelectorAll('.scroll');

                // For each smooth scroll link
                [].forEach.call(scrollToggle, function (toggle) {

                    // When the smooth scroll link is clicked
                    toggle.addEventListener('click', function (e) {

                        // Prevent the default link behavior
                        e.preventDefault();

                        // Get anchor link and calculate distance from the top
                        var dataID = toggle.getAttribute('href');
                        var dataTarget = document.querySelector(dataID);
                        var dataSpeed = toggle.getAttribute('data-speed');

                        // If the anchor exists
                        if (dataTarget) {
                            // Scroll to the anchor
                            smoothScroll(dataTarget, dataSpeed || 1000);
                        }

                    }, false);

                });

            }

        })();



    }

    GetBanner() {
        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = []
            this.arr.push({
                // ServicePrice: 0

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');
            //var url = "api/Nursing/MN_Banners_CRUD";
            var url = this.HomeUrl + "api/NovusChem/NC_Banners_CRUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {

                this.dataResult = data;

            }, err => {
                this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later', 'error');
            });
        });
    }

}
