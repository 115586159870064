<div class="container-fluid">
    <div id="about" class="about-area ptb-100" style="padding-bottom:0;">
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel" data-interval="3000">
            <div class="carousel-inner">
                <div class="carousel-item" *ngFor="let item of dataResult; let i = index" [class.active]="i === 0">
                    <img [src]="HomeUrl + item.BannerImage" alt="Novuschem" class="d-block">
                </div>
            </div>
            <!--<a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>-->
            <div class="container">
                <ol class="carousel-indicators">
                    <li *ngFor="let item of dataResult; let i = index"
                        [attr.data-target]="'#carouselExampleFade'"
                        [attr.data-slide-to]="i"
                        [class.active]="i === 0">
                    </li>
                </ol>
            </div>
        </div>

    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <br />
            <marquee #productContainer class="marquee-container1">
                <div class="marquee">
                    <div *ngFor="let item of ProductsLists"
                         class="product-item"
                         (click)="navigateToProduct(item)"
                         (mouseenter)="stopScroll()"
                         (mouseleave)="startScroll()">
                        <span style="margin-bottom: 5px;">{{item.CAS_No}}</span>&nbsp; |
                        <span>{{item.ProductName}}</span>
                        <span class="badge badge-danger badge-pill text_left custom-badge">Hot Products</span>
                    </div>
                </div>
            </marquee>
        </div>
    </div>
    </div>

    <div class="container-fluid ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div id="login-container">
                        <div class="profile-img"></div>
                    </div>
                    <h6>
                        Best-in-Class Innovation
                    </h6>
                    <div class="description">
                        Maddie is a front end web developer in New York. She has worked in the field for 10 years now. Check out her projects in the links below. She is available for hire as well.
                    </div>

                    <div class="readmore" routerLink="/About">Read More >></div>
                </div>
                <div class="col-lg-3">
                    <div id="login-container">
                        <div class="profile-img profile-img1"></div>
                    </div>
                    <h6>
                        Unique Product Line
                    </h6>
                    <div class="description">
                        Maddie is a front end web developer in New York. She has worked in the field for 10 years now. Check out her projects in the links below. She is available for hire as well.
                    </div>

                    <div class="readmore" routerLink="/About">Read More >></div>


                </div>
                <div class="col-lg-3">
                    <div id="login-container">
                        <div class="profile-img profile-img2"></div>
                    </div>
                    <h6>
                        Uncompromising Quality
                    </h6>
                    <div class="description">
                        Maddie is a front end web developer in New York. She has worked in the field for 10 years now. Check out her projects in the links below. She is available for hire as well.
                    </div>

                    <div class="readmore" routerLink="/About">Read More >></div>


                </div>
                <div class="col-lg-3">
                    <div id="login-container">
                        <div class="profile-img profile-img3"></div>
                    </div>
                    <h6>
                        Competitive Pricing
                    </h6>
                    <div class="description">
                        Maddie is a front end web developer in New York. She has worked in the field for 10 years now. Check out her projects in the links below. She is available for hire as well.
                    </div>

                    <div class="readmore" routerLink="/About">Read More >></div>


                </div>
            </div>

        </div>

    </div>
    <style>
        @keyframes marqueeAnimation {
            0% {
                transform: translateX(0%);
            }
            /* Start from the left */
            100% {
                transform: translateX(-100%);
            }
            /* End at the left after scrolling */
        }

        .marquee-container1 {
            overflow: hidden;
            white-space: nowrap;
            border-bottom: 1px solid #f5f6f7;
            margin-bottom: 1%;
            letter-spacing: 1px;
        }

        .marquee {
            display: flex;
            /*animation: marqueeAnimation 50s linear infinite;*/
        }

        .product-item {
            margin-right: 20px;
            cursor: pointer;
            border-radius: 8px;
            border: 1px solid #ccc;
            padding: 5px;
            transition: all 0.3s ease-in-out;
        }

            .product-item:hover {
                border: none;
                color: white;
                background-color: grey;
            }

        .marquee:hover {
            animation-play-state: paused;
            cursor: pointer;
        }

        /* Form submit button */
        .search-wrapper button {
            overflow: visible;
            position: relative;
            float: right;
            border: 0;
            padding: 0;
            cursor: pointer;
            height: 2.4rem;
            width: 72px;
            color: white;
            text-transform: uppercase;
            background: #8b8b8b;
            border-radius: 0 3px 3px 0;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);
            margin-left: -10%;
            margin-right: -27%;
        }

            .search-wrapper button:hover {
                background: #8b8b8b;
            }

            .search-wrapper button:active,
            .search-wrapper button:focus {
                background: #8b8b8b;
                outline: 0;
            }

            .search-wrapper button:before { /* left arrow */
                content: '';
                position: absolute;
                border-width: 8px 8px 8px 0;
                border-style: solid solid solid none;
                border-color: transparent #8b8b8b transparent;
                top: 12px;
                left: -6px;
            }

            .search-wrapper button:hover:before {
                border-right-color: #8b8b8b;
            }

            .search-wrapper button:focus:before,
            .search-wrapper button:active:before {
                border-right-color: #8b8b8b;
            }

            .search-wrapper button::-moz-focus-inner { /* remove extra button spacing for Mozilla Firefox */
                border: 0;
                padding: 0;
            }


        .ui-inputtext:enabled:focus {
            border-color: none !important;
            outline: 0 none;
            box-shadow: none;
            outline: none !important;
        }

        .search-wrapper button:before {
            content: '';
            position: absolute;
            border-width: 8px 8px 8px 0;
            border-style: solid solid solid none;
            border-color: transparent #8b8b8b transparent;
            top: 9px !important;
            left: -6px;
        }

        .ui-inputtext:enabled:hover: {
            border-color: none !important;
        }


        /* Form wrapper styling */




        .maring_t_3 {
            margin-top: 3%;
        }


        .img_text {
            font-size: 17px;
            letter-spacing: 1px;
            padding-top: 4%;
            font-weight: bold;
        }

        ::-webkit-input-placeholder { /* Edge */
            color: #3d3d3d !important;
        }

        .search-box {
            position: relative;
            width: 100%;
            max-width: 360px;
            height: 60px;
            border-radius: 120px;
            margin: 0 auto;
        }

        .search-icon, .go-icon {
            position: absolute;
            top: 0;
            height: 60px;
            width: 86px;
            line-height: 61px;
            text-align: center;
        }

        .search-icon {
            left: 0;
            pointer-events: none;
            font-size: 1.22em;
            will-change: transform;
            transform: rotate(0deg) !important;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform-origin: center center;
            -webkit-transform-origin: center center;
            -moz-transform-origin: center center;
            -o-transform-origin: center center;
            transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -webkit-transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -moz-transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -o-transition: transform 400ms 220ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
        }

        .si-rotate {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
        }

        .go-icon {
            right: 0;
            pointer-events: none;
            font-size: 1.38em;
            will-change: opacity;
            cursor: default;
            opacity: 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -webkit-transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -moz-transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -o-transition: opacity 190ms ease-out, transform 260ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
        }

        .go-in {
            opacity: 1;
            pointer-events: all;
            cursor: pointer;
            transform: rotate(0);
            -webkit-transform: rotate(0);
            -moz-transform: rotate(0);
            -o-transform: rotate(0);
            transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -webkit-transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -moz-transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
            -o-transition: opacity 190ms ease-out, transform 260ms 20ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
        }

        .search-border {
            display: block;
            width: 100%;
            max-width: 500px;
            height: 60px;
        }

        .border {
            fill: none;
            stroke: #c1c1c1;
            stroke-width: 2;
            stroke-miterlimit: 10;
        }

        .border {
            stroke-dasharray: 740;
            stroke-dashoffset: 0;
            transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
            -webkit-transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
            -moz-transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
            -o-transition: stroke-dashoffset 400ms cubic-bezier(0.600, 0.040, 0.735, 0.990);
        }

        .border-searching .border {
            stroke-dasharray: 740;
            stroke-dashoffset: 459;
            transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
            -webkit-transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
            -moz-transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
            -o-transition: stroke-dashoffset 650ms cubic-bezier(0.755, 0.150, 0.205, 1.000);
        }

        #search {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 120px;
            border: none;
            background: rgba(255,255,255,0);
            padding: 0 68px 0 68px;
            color: #000;
            font-size: 1.32em;
            letter-spacing: -0.015em;
            outline: none !important;
        }

            #search::-webkit-input-placeholder {
                color: #c1c1c1;
            }

            #search::-moz-placeholder {
                color: #000;
            }

            #search:-ms-input-placeholder {
                color: #000;
            }

            #search:-moz-placeholder {
                color: #000;
            }

            #search::-moz-selection {
                color: #000;
                background: rgba(0,0,0,0.25);
            }

            #search::selection {
                color: #FFFFFF;
                background: rgba(0,0,0,0.25);
            }

        .cd_w {
            width: 18%;
            outline: none;
        }

        .cds {
            border: 1px solid #c1c1c1;
            padding: 10% 2% 10% 2%;
            border-radius: 6px;
            text-align: center;
            box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
            background: #00000000;
            color: #000;
            font-size: 13px;
            height: 65px;
            letter-spacing: 0.5px;
            cursor: pointer;
            outline: none !important;
        }

            .cds:hover {
                border: 1px solid #d7127b;
                padding: 10% 2% 10% 2%;
                border-radius: 6px;
                text-align: center;
                box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
                background: #d7127b;
                color: #fff;
                font-size: 13px;
                height: 65px;
                letter-spacing: 0.5px;
                cursor: pointer;
                outline: none;
            }

                .cds:hover:before {
                    top: 0;
                }

        .cds1 {
            border: 1px solid #c1c1c1;
            padding: 7%;
            border-radius: 6px;
            text-align: center;
            box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
            background: #ffffff;
            color: #000000;
            font-size: 13px;
            padding-top: 8%;
            letter-spacing: 0.5px;
            cursor: pointer;
            outline: none !important;
            width: 180px !important;
        }

        .cds2 {
            border: 1px solid #c1c1c1;
            padding: 7%;
            border-radius: 5px;
            text-align: left;
            box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
            background: #ffffff;
            color: #000000;
            font-size: 13px;
            padding-top: 10px;
            padding-bottom: 2px;
            letter-spacing: 0.5px;
            cursor: pointer;
            outline: none !important;
            width: 450px !important;
            /* margin-top:-3%*/
        }

        .cds1:hover {
            /*border: 1px solid #d7127b;*/
            padding: 7%;
            border-radius: 6px;
            text-align: center;
            box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
            background: rgba(144, 144, 144, 0.39);
            /*color: #fff;*/
            font-size: 13px;
            padding-top: 8%;
            letter-spacing: 0.5px;
            cursor: pointer;
            outline: none !important;
            width: 180px !important;
            transition: background-color 1s;
        }

        .cds2:hover {
            /*border: 1px solid #d7127b;*/
            padding: 7%;
            /* border-radius: 20px;*/
            text-align: left;
            box-shadow: 2px 13px 14px 5px rgba(0,0,0,0.2);
            background: rgba(144, 144, 144, 0.39);
            /*color: #fff;*/
            font-size: 13px;
            padding-top: 10px;
            padding-bottom: 2px;
            letter-spacing: 0.5px;
            cursor: pointer;
            outline: none !important;
            width: 450px !important;
            transition: background-color 1s;
        }

        .banner { /*background-image: url("../../assets/bg.png");*/
            /*--background-image: url("https://ak2.picdn.net/shutterstock/videos/929752/thumb/1.jpg");*/
            background-image: url(../../assets/ALSBgIMG.jpg);
            /*background-image: url('../../assets/generics_bg.jpg');*/ /* Full height */
            height: 105vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

            .banner .test {
                text-align: center;
                margin-top: 35vh
            }

        .row-banner {
            text-align: left;
            margin-top: 30vh
        }

        .banner h1 {
            color: white;
        }

        ol, ul {
            margin-top: 0;
            margin-bottom: 10px;
            padding-left: 18px;
            line-height: 2.5;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: #000;
            font-family: poppins;
        }

        .div_text {
            font-size: 14px;
            letter-spacing: 0.5px;
            margin-left: -4rem;
            margin-top: 1.1rem;
        }

        .text_font {
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 33px;
            color: #000;
            text-align: justify;
        }
    </style>
