import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
declare var $: any;
@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.scss']
})
export class SearchresultsComponent {
    loginDet:any
    loginDetails:any
    HomeURL:any
    searchText:any
    Reportdata:any
    cols:any
    searchtext:any
    constructor(public http: HttpClient, public cartService: CartService, public activeroute: ActivatedRoute,

        public generalService: GeneralService, public router: Router) {
        debugger
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        debugger
        this.searchtext = this.activeroute.snapshot.paramMap.get('searchtext').replace(/\_/g, " ").trim();

       
        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });

    }

    ngOnInit(): void {

        
        this.cols = [
            { field: 'CategoryName', header: 'CategoryName' },
            { field: 'ProductName', header: 'ProductName' },
            { field: 'Catalog_No', header: 'Catalog_No' },
            { field: 'CAS_No', header: 'CAS_No' },
            { field: 'HSN', header: 'HSN' },
          
        ];
        this.getProducts();
    }


    getProducts() {
   

        const formData = new FormData();
        formData.append('Param', this.searchtext);
        const url = 'api/NovusChem/NC_Get_SearchResults';
        this.generalService.PostData(url, formData).then(
            (data) => {
                this.Reportdata = data;
            },
            (err) => {
                //  this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }

    navigatingToSearchedProducts(pro:any) {
        debugger
        localStorage.setItem('ProName', pro.ProductName)
        const url = `${pro.CategoryName}-${pro.CAS_No}-${pro.ProductName}`;
        this.router.navigate(['/Product/', url]).then(() => {
            // Reload the page
            window.location.reload();
        });
    }
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }
}
