
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;">
        <div class="container">
            <ul>
                <div style="color:#d03737;">Contact Us</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:#d03737;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:#d03737;">Contact</li>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="container1">
                   
                    <img src="img/shape.png" class="square" alt="" />
                    <div class="form">
                        <div class="contact-info">
                            <h3 class="title">Let's get in touch</h3>
                            <p class="text">
                                The company was founded on a solid foundation and has since grown on trust.
                            </p>

                            <div class="info">
                                <div class="information">
                                    <i class="fas fa-map-marker-alt"style="margin-top: -20px;"></i> &nbsp; &nbsp;

                                    <p>
                                        B-64, Pushkar Industrial & Logistic Park B/H. Satyam Mall,
                                        Changodar, Ahmedabad-382210, Gujarat, India
                                    </p>
                                </div>
                                <div class="information">
                                    <i class="fas fa-envelope"style="margin-top: 8px;"></i> &nbsp; &nbsp;
                                    <p>info@novuschems.com</p>
                                </div>
                                <div class="information">
                                    <i class="fas fa-phone"style="margin-top: 8px;"></i>&nbsp;&nbsp;
                                    <p>+91 96647 13350</p>
                                </div>
                            </div>

                            <div class="social-media">
                                <p>Connect with us :</p>
                                <div class="social-icons">
                                    <a href="#">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a href="#">
                                        <i class="fab fa-instagram"></i>
                                    </a>
                                    <a href="#">
                                        <i class="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="contact-form">
                            <span class="circle one"></span>
                            <span class="circle two"></span>

                           
                            <div class="col-lg-7 col-md-12">
                                <div class="contact-form" style="height: 29rem;background-color: #d03737;">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            <label style="font-size: 17px;font-weight: 400;color: #ffffff;">Name</label>
                                            <input maxlength="50" [(ngModel)]="Fullname" [ngModelOptions]="{standalone: true}" />
                                        </div>
                                        &nbsp;
                                        <div class="col-lg-12 col-md-12">
                                            <label style="font-size: 17px;font-weight: 400;color: #ffffff;">Email</label>
                                            <input maxlength="50" [(ngModel)]="email" [ngModelOptions]="{standalone: true}"  />
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <br />
                                            <label style="margin-left: -2px;font-size: 17px;font-weight: 400;color: #ffffff;">Message</label>
                                            <textarea [(ngModel)]="Message" [ngModelOptions]="{standalone: true}" style="height: 9rem;"></textarea>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <button class="btn" (click)="sendmail()" style=" margin-top:1rem;">Submit</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3674.275518586205!2d72.45057247407189!3d22.94007811940413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e910d6691b1b5%3A0xe45f0445ce8ef519!2sNovus%20ChemStrength!5e0!3m2!1sen!2sin!4v1722428585634!5m2!1sen!2sin" width="550" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</div>
