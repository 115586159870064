import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'

@Component({
    selector: 'app-products',

    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})


export class ProductsComponent {
    loginDet: any;
    loginDetails: any;
    searchedProductsCount: number = 0;
    originalCategory: any[] = [];
    categoryName: string;
    arr: any = [];
    category: any = [];
    category1: any;
    isGridView: boolean;
    activeCategoryID: number;
    filteredProducts: any;
    HomeUrl: any;
    selectedProduct: any;
    image: any; activeCategoryname: any;
    token: any;
    filteredProductCount: number;
    Count: any;
    Counts: any;
    constructor(private route: ActivatedRoute, private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, public http: HttpClient) {
        debugger
        this.GetCategory();
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
           
            this.loginDet = localStorage.getItem("LoginDetails");
        });


    }
    ngOnInit(): void {
        debugger
        this.GetCategory();

        this.route.queryParams.subscribe(params => {
            this.activeCategoryID = +params['categoryID'] || this.originalCategory[0].CategoryID;
            this.getProducts(this.activeCategoryID);
        });
        this.route.queryParams.subscribe(params => {
            const categoryID = +params['categoryID']; // Convert to number
            if (!isNaN(categoryID)) {
                this.getProducts(categoryID);
            }
        });

        // Set activeCategoryID directly here
       
        const initialCategorynam = +this.route.snapshot.queryParams['CategoryName'];
        if (!isNaN(initialCategorynam)) {
            this.activeCategoryname = initialCategorynam;
        }
       
        this.categoryName = localStorage.getItem("cat");
         
        this.GetProductsCount();

    }

    onProductClick(product: any): void {
        this.selectedProduct = product;
    }
    GetCategory(){

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/NovusChem/NC_Category_Crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {
                    debugger

                    this.originalCategory = data;
                    if (this.category && this.category.length > 0) {
                      
                        this.activeCategoryID = this.category[0].CategoryId;
                 
                        this.filteredProductCount = this.getFilteredProductCount(this.activeCategoryID);
                    }
                    this.isGridView = true;

                },
                (err) => {
                           });
            this.isGridView = true;
        });
    }



   

    getProducts(searchValue: any) {
        if (!searchValue) {
            this.category = null; // Clear the category if searchValue is empty
            this.searchedProductsCount = 0; // Reset the search count
            return; // Do nothing if searchValue is empty
        }

        const formData = new FormData();
        formData.append('Param', searchValue);

        const url = 'api/NovusChem/Get_Produtcs_Basedon_CatId';
        this.generalService.PostData(url, formData).then(
            (data) => {

                this.category = data;
                this.searchedProductsCount = this.category.length; // Update the count after receiving data
            },
            (err) => {
                this.generalService.Alerterror('< img src = "../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong', '');
            }
        );
    }


   
    getFilteredProductCount(categoryID: any): number {

        return this.filteredProducts.filter(product => product.CategoryID === categoryID).length;
    }


    navigatingToSearchedProduct(pro:any) {
        debugger

       

        const url = `${this.categoryName}-${pro.CAS_No}-${pro.ProductName}`;
        this.router.navigate(['/Product/', url]);
        localStorage.setItem('ProName', pro.ProductName)
    }
  

    GetProductsByCategory(categoryID: number) {

        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }

            this.activeCategoryID = categoryID;
            var UploadFile = new FormData();
            UploadFile.append("Param1", categoryID.toString());


            var url = this.HomeUrl + "api/NovusChem/Get_Produtcs_Basedon_CatId";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => {


                    this.category1 = data; // Populate category1 with the received data
                    this.image = this.HomeUrl + this.category1[0].Record_Image

                    this.searchProducts("");
                },
                (err) => {
                    //this.generalService.Alerterror('<img src="../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong, please try again later', '<img src="../../../assets/icons/icons8-cancel.gif" />');
                });
        });
    }

    GetProductsCount() {
         
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }

            var url = this.HomeUrl + "api/Azole/Prodcutscount";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}` // Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.get(url, { headers }).subscribe(
                (data: any) => {
                  
                    this.Count = data;
                    this.Counts = this.Count[0]
                },
                (err) => {
                   // this.generalService.Alerterror('<img src="../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong, please try again later', '<img src="../../../assets/icons/icons8-cancel.gif" />');
                });

        });
    }

    getCategoryName(categoryID: number): string {
        const category = this.originalCategory.find(item => item.CategoryID === categoryID);
        const categoryName = category ? category.CategoryName : ''; // Get category name if found, otherwise empty string
        localStorage.setItem("cat", categoryName); // Store the categoryName in local storage
        return categoryName; // Return the category name
    }


    searchProducts(searchTerm: string) {

        if (this.category1) {
            const searchTermLower = searchTerm.toLowerCase(); // Convert search term to lowercase


            this.filteredProducts = this.category1.filter(product =>
                product.CAS_No.toLowerCase().includes(searchTermLower) ||
                product.HSN.toLowerCase().includes(searchTermLower) ||
                product.Name.toLowerCase().includes(searchTermLower),

            );
        }
    }


}
