import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
declare var $: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

    
    regdata: any;
    totalPrice: any;
    OrderDetails: any;
    Id: any;
    deliveryCharge: any;
    offerPrice: any = 0;
    GstPrice: any;
    offerDetails: any;
    offerCode: any;
    WishListitemmsforloginusers: any = [];
    cartIndex: any;
    n: any;
    val: number;
    arr: any = [];
    lineitems: any = [];
    PaymentType: any;
    addressId: any;
    currentCartItems: any = [];
    customerCartItems: any = [];
    loginStatus: boolean;
    addressDetails: any;
    addressDetails1: any;
    LoginDetails: any;
    itemSize: any;
    ItemId: any;
    seletedItemforWishlist: any = [];
    wishListItems: any = [];
    subTotal: any = 0;
    HomeURL: any;
    cartItems: any;
   
    offersdata: any;
    offerId: any;
    alert: any;
    //getAllAdminOrders: any;
    plus: boolean = true;
    TransactionStatus: any;
    admin: any;
    loginDetails: any;
    loginDet: any;
    AID: any;
    orderno: any;
    sclname: any;
    Price: any;
    gtprice: any;
    Gstper: any;
    quantity: any; TaxnId: any;
    items: any;
    defaultQuantity = 1;
    gstprice: any;
    Gamount: any;
    Grandtotal=0;
    constructor(public http: HttpClient, public cartService: CartService,

        public generalService: GeneralService, public router: Router) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
       debugger
        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res
            this.items = this.cartItems
          
        })
        this.cartItems.forEach((item: any) => {
            debugger
            this.subTotal = (item.PackPrice * 1) * (item.Quantity); // Assuming Price is a number
            const gstTax = this.subTotal * (item.GSTTAX / 100);
            this.Grandtotal += this.subTotal + gstTax
            this.Gamount = this.Grandtotal 
            localStorage.setItem('Grantotal', this.Gamount);
        });
        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });
      
    }

    ngOnInit(): void {
        debugger
        if (this.loginDetails) {
            this.loginStatus = true;
            this.getCustomerAddress();
        }
        else {
            this.loginStatus = false;
        }
      
    }

    /*
    Below procedtoBuy() method will fire when the user click on proced to by button
     */
    procedtoBuy() {
        debugger
        if (this.LoginDetails) {
            if (this.addressDetails) {
                if (!this.addressId) {
                    this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please select or add address for Delivery.', '');
                }
                else {

                    $('#myModal').modal('show');

                }
            } else {
                this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please add address for Delivery.', '');
                this.router.navigate(['/Address']);
            }
        }
    }


    navigatae() {
        debugger
      
        this.router.navigate(['/Products'], { queryParams: { categoryID: 1 } });
    }



    /*Below deleteFromCart() method will fire when user delete the product from the cart*/
    deleteFromCart(index) {
        this.cartService.removeCartItems(index)

    }
    increaseQuantity(item) {
        debugger;
        item.Quantity++; // Increase quantity of the clicked item

        // Update quantity for all cart items
        this.cartItems.forEach((cartItem: any) => {
            if (cartItem.PackageId === item.PackageId) {
                cartItem.Quantity = item.Quantity;
            }
        });
        this.subTotal = 0
        this.totalPrice = 0
        this.Gstper = 0;
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            debugger
            this.subTotal += (a.PackPrice * 1) * (a.Quantity)
            this.gstprice = this.subTotal * (a.GSTTAX / 100);

            this.Grandtotal = this.subTotal + this.gstprice
            this.Gamount = this.Grandtotal 
            localStorage.setItem('Grantotal', this.Gamount);
        })
    }

    decreaseQuantity(item) {
        debugger
        if (item.Quantity > 1) {
            item.Quantity--; // Decrease quantity of the clicked item, ensuring it doesn't go below 1
        }
        
        // Update quantity for all cart items
        this.cartItems.forEach((cartItem: any) => {
            if (cartItem.PackageId === item.PackageId) {
                cartItem.Quantity = item.Quantity;
            }
        });
        this.subTotal = 0
        this.totalPrice = 0
        this.Gstper = 0;
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            debugger
            this.subTotal += (a.PackPrice * 1) * (a.Quantity)
            this.gstprice = this.subTotal * (a.GSTTAX / 100);

            this.Grandtotal = this.subTotal + this.gstprice
            this.Gamount = this.Grandtotal
            localStorage.setItem('Grantotal', this.Gamount);
        })
    }

    calculateSubTotal(item) {
       // return (item.PackPrice * item.Quantity)
       
        return (item.PackPrice * item.Quantity) + ((item.PackPrice * item.Quantity) * (item.GSTTAX) / 100);
    }
  



    deleteCartfromTable(index: number, PackageId: any) {
        debugger
        this.cartItems.splice(index, 1); // Removes the item from the cartItems array based on index

        // Loop through cartItems to find and remove the item with matching productId from local storage
        for (let i = 0; i < this.cartItems.length; i++) {
            if (this.cartItems[i].PackageId === PackageId) {
                this.cartItems.splice(i, 1); // Remove the item from cartItems array
                break; // Exit the loop once the item is found and removed
            }
        }

        // Save the updated cartItems array to local storage
        localStorage.setItem("cartItems", JSON.stringify(this.cartItems));

        if (this.cartItems.length === 0) {
            // If cartItems is empty after deletion, clear both "Packagesarr" and "cartItems" from local storage
            localStorage.removeItem("Packagesarr");
            localStorage.removeItem("cartItems");

            // Show a warning alert
            this.generalService.Alertwarning('<img src = "../../../assets/icons/icons8-warning.gif" />', 'No items in the cart.', '');

            // Redirect to Products page with categoryID 1
            this.router.navigate(['/Products'], { queryParams: { categoryID: 1 } });

            // Clear the cart service
            this.cartService.getProducts();
        }

        // Reload the current page
       // location.reload();
    }




  



    getCustomerAddress() {
        debugger

        let formData: FormData = new FormData();
        formData.append('Param', this.loginDetails[0].RegId);
        var url = "api/Azole/Get_userdetails_basedon_UserID";

        this.generalService.PostData(url, formData).then((data: any) => {
            if (data != "") {
                this.regdata = data;
                this.plus = false
            }
        });
    }


    PlaceOrder() {
       

            debugger

            this.arr = [];
            this.n = ""
            var d = new Date();
            this.n = d.getTime();

            for (var i = 0; i < this.cartItems.length; i++) {
                this.arr.push({



                    ProductId: this.cartItems[i].Productid,
                    Quantity: this.cartItems[i].Quantity,



                 
                    Catalog_No: this.cartItems[i].Catalog_No,
                    PackPrice: this.cartItems[i].PackPrice,
                  
                    HSN: this.cartItems[i].HSN,
                    CAS: this.cartItems[i].CAS,
                    ProductName: this.cartItems[i].ProductName,
                    GSTTAX: this.cartItems[i].GSTTAX,
                  
                    PackageId: this.cartItems[i].PackageId,


                    TotalPrice: this.Gamount,




                })
            
            this.lineitems = []
            for (var i = 0; i < this.cartItems.length; i++) {
                this.lineitems.push({
                    ProductName: this.cartItems[i].ProductName ,
                    PackName: this.cartItems[i].PackName ,
                    Rate: this.cartItems[i].PackPrice,
                    Quantity: this.cartItems[i].Quantity,             
               
                })
            }

            localStorage.setItem("LineItems", JSON.stringify(this.lineitems))


            localStorage.setItem("Packagesarr", JSON.stringify(this.arr));
            this.router.navigate(['/BuyNow']);

        }
    }

    cancel() {
        $('#myModal').modal('hide');
    }


    getSelectedAddress(addressId: any) {
        this.AID = addressId;
        //console.log(addressId)
    }

    /*
       Below method is used to call the delivery mode whether it is online or offline
    */
    getPaymentType(pType: string) {
        this.PaymentType = pType
    }

    selectmode() {
        debugger
        $('#myModal').modal('show');
    }



    navigatingToProduct(data) {
        var prodName = "";
        var str = data.split(" ")//spliting the product name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.router.navigate(['/Product/' + prodName]); ///sv
    }






}

