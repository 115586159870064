<footer class="footer-area">

    <div class="container">

        <div class="row">


            <div class="col-lg-4">
                <!--<h3 class="Azole">Novuschem</h3>-->

                <img src="assets/Images/Whitelogo.png" routerLink="/Home" />
                <div class="mt-3" style="color:white;">
                    Novus ChemStrength excels in providing custom synthesis solutions, ranging from process development to scale-up and sourcing of complex molecules, tailored to meet the unique needs of researchers and scientists.
                </div>

                <br /> <br />

            </div>

            <div class="col-lg-5">
                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-6"><h3>Our Links</h3></div>
                    <div class="col-lg-3"></div>

                </div>

                <div class="row" style="margin-top:-5%">
                    <div class="col-lg-6">
                        <ul class="magnific-image">
                            <li routerLink="/Home">Home</li>
                            <li routerLink="/About">About Us</li>
                            <li routerLink="/Products">Products</li>
                            <li routerLink="/RequestForQuote">Request Products</li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <ul class="magnific-image">
                            <li routerLink="/HowToOrder">How to Order</li>
                            <li routerLink="/SearchCOA">COA Search</li>
                            <li routerLink="/Supplier">Become A Supplier</li>
                            <li routerLink="/Contact">Contact Us</li>
                        </ul>
                    </div>

                </div>

            </div>

            <div class="col-lg-3 mobile contact">
                <a href="/ContactUs" class="">
                    <h3>Social Links</h3>
                </a>
                <ul class="social-links">
                    <li><a href="https://www.facebook.com" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="https://www.instagram.com" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="https://wa.me/1234567890" target="_blank" title="WhatsApp"><i class="fab fa-whatsapp"></i></a></li>
                    <li><a href="https://twitter.com" target="_blank" title="Twitter"><i class="fab fa-twitter"></i></a></li>
                </ul>

                <!--<address>
        <div style="line-height:2">
            B-64, Pushkar Industrial & Logistic Park B/H. Satyam Mall,
            Changodar, Ahmedabad-382210, Gujarat, India
        </div>

        <div style="line-height:2"><a href="tel:+919664713350" target="_blank"><i class="fa fa-phone"></i>&nbsp;+91 9664713350</a></div>
        <div style="line-height:2">
            <a href="mailto:info@azolechem.com" target="_blank">
                <i class="fa fa-envelope"></i>&nbsp;
                info&#64;novuschems.com
            </a>
        </div>
    </address>-->
                <!--<ul class="social_links">
        <li><a href="https://www.facebook.com/people/Azole-Chem-Private-Limited/61558462372150"><i class="fab fa-facebook-f icon_"style="color:white;"></i></a></li>
        <li><a href="https://twitter.com/AzoleChem"><i class="fab fa-twitter icon_"style="color:white;"></i></a></li>
        <li><a href="https://www.instagram.com/azolechem"><i class="fab fa-instagram icon_"style="color:white;"></i></a></li>
        <li><a href="https://in.linkedin.com/company/azole-chem-pvt-ltd"><i class="fab fa-linkedin-in icon_"style="color:white;"></i></a></li>
    </ul>-->




            </div>


        </div>


        <hr style="color: aliceblue;" />
        <div class="row">
            <div class="col-lg-6 ">
                <div class="rights">
                    <i class="far fa-copyright" style="color:white;"></i>
                    All rights are reserved by Novuschems
                </div>
            </div>

            <div class="col-lg-6">
                <div class="powered">
                    Powered by <a href="https://gagri.net/" target="_blank">
                        <img class="gagri2" src="../../../assets/Images/gagrilogo.webp" alt="Gagri" />
                    </a>
                </div>
            </div>
        </div>




    </div>






</footer>

<div class="mobile-footer">
    <ul class="social-icons">
        <li><a href="#"><i class=" iconn fa fa-home"></i><br />home  </a></li>
        <li><a href="#"><i class=" iconn fa fa-search"></i><br /> search</a></li>
        <li><a href="#"><i class=" iconn fa fa-shopping-bag"></i><br /> order</a></li>
        <li><a href="#"><i class=" iconn fa-solid fa-box-open"></i><br /> Products</a></li>
        <li><a href="#"><i class=" iconn fa-solid fa-user"></i><br /> Supplier</a></li>
    </ul>
</div>




<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>
