<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;">
        <div class="container">
            <ul>
                <div style="color:#d03737;">My Profile</div>

                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:#d03737;">  <i class="fas fa-home"></i>  Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:#d03737;">My Profile</b></li>
            </ul>
        </div>
    </nav>



    <div class="container">

        <fieldset class="mt-3"style="border: 1px solid #ef883c;">
        
             
                    <div class="card text-black" style="border-radius:0;border:none;box-shadow:none">
                        <div class="card-body p-md-5">
                            <div class="row  ">
                               
                                    <!--<p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>-->

                                    <form class="mx-1 mx-md-4" [formGroup]="form">
                                        <div class="row">
                                            <!--<div class="col-lg-3">
                                                <div class="d-flex flex-row align-items-center mb-4">
                                                  
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label class="form-label" for="form3Example1c">Company Name</label>
                                                        <input type="text" formControlName="CompanyName" name="fullName" id="fullName" class="form-control" required />
                                                    </div>
                                                </div>
                                            </div>-->

                                            <div class="col-lg-3">
                                                <div class="d-flex flex-row align-items-center mb-4">
                                                   
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label class="form-label" for="form3Example1c">Your Name</label>
                                                        <input type="text" formControlName="UserName" name="fullName" id="fullName" class="form-control" required />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="d-flex flex-row align-items-center mb-4">
                                                   
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label class="form-label" for="form3Example3c">Your Email</label>
                                                        <input type="text" formControlName="EMailID" name="fullName" id="fullName" class="form-control" required />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="d-flex flex-row align-items-center mb-4">
                                                   
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label class="form-label" for="form3Example3c">Your Mobile Number</label>
                                                        <input type="text" formControlName="Phone1" name="fullName" id="fullName" class="form-control" (input)="CheckMobileNo()" maxlength="10" required />
                                                        <small class="text-danger" *ngIf="form.get('Phone1').touched"readonly>
                                                            <span *ngIf="form.get('Phone1').hasError('required')">Mobile is required</span>
                                                            <span *ngIf="checkNo==10">Mobile Number Exist</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="d-flex flex-row align-items-center mb-4">
                                                  
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label class="form-label" for="form3Example3c">Your city</label>
                                                        <input type="text" formControlName="City" name="fullName" id="fullName" class="form-control" required />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="d-flex flex-row align-items-center mb-4">
                                                     
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label class="form-label" for="form3Example3c">Password</label>

                                                        <div class="input-group">
                                                            <input type="{{ passwordshow ? 'text' : 'password' }}" formControlName="PWD" name="password" id="password" class="form-control" required />
                                                            <div class="input-group-append">
                                                                <span class="input-group-text toggle-password" (click)="togglePasswordVisibility()">
                                                                    <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                                                                </span>
                                                            </div>
                                                        </div>


                                                        <small class="text-danger" *ngIf="form.get('PWD').touched">
                                                            <span *ngIf="form.get('PWD').hasError('required')">Password is required</span>
                                                            <span *ngIf="form.get('PWD').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-3"> 

                                                <div class="d-flex flex-row align-items-center mb-4">
                                                   
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label class="head_txt"> Role Name<span style="color:red;">*</span></label>
                                                        <div class="form-group mt-n2">
                                                            <p-autoComplete [suggestions]="BranchdropList" (completeMethod)="filterGroupsSingle4($event)" (onSelect)="onGSTSelect($event)"  dataKey='Id' field="RoleName"
                                                                            formControlName="RoleName" [dropdown]="true" [size]="30" style="margin-top:10px"
                                                                            placeholder="Search with RoleName" [minLength]="1" [forceSelection]="true">
                                                                <ng-template let-autofillCustomers pTemplate="item">
                                                                    <div class="card">
                                                                        {{autofillCustomers.RoleName}}
                                                                    </div>
                                                                </ng-template>
                                                            </p-autoComplete>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <div class="d-flex flex-row align-items-center mb-4">
                                                    
                                                    <div class="form-outline flex-fill mb-0">
                                                        <label for="GSTNo">GST Number<span style="color:red">*</span></label>
                                                        <input type="text" class="form-control" id="GSTNo" formControlName="GSTNo" placeholder="GSTNo" maxlength="15" />
                                                    </div>
                                                </div>
                                            </div>

                                            <!--<div class="form-check d-flex justify-content-center mb-5">
        <div class="row">
            <div class="col-lg-1">
                <input class="form-check-input me-2" style="border:1px solid orangered" type="checkbox" value="" id="form2Example3c" />
            </div>
            <div class="col-lg-11">
                <label class="form-check-label" for="form2Example3">
                    I agree all statements in <a href="#!">Terms of service</a>
                </label>
            </div>

        </div>

    </div>-->


                                        </div>
                                    </form>
                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                        <button type="button" class="btn btn-primary btn-lg" (click)="onSubmit()">Update</button>
                                    </div>
                               
                                <!--<div class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">
                                <br />
                                <img src="https://img.freepik.com/premium-vector/online-registration-illustration-design-concept-websites-landing-pages-other_108061-938.jpg"
                                     class="img-fluid" style="width:80%" alt="Sample image">

                            </div>-->
                            </div>
                        </div>
                    </div>
                
            
        </fieldset>

    </div>

</div>

