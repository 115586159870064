<div id="about" class="about-area ptb-100" style="padding-bottom:0">
    <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;">
        <div class="container" style="margin-top:10px">
            <ul>
                <div style="color:#d03737;">Request Product</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:#d03737;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:#d03737;">Unlock the power of Personalization</li>
            </ul>
        </div>
    </nav>
    <div class="container">
        <br />
        <h6 style=" color:red;text-align:center"><b>Request a customized product tailored to your unique needs and preferences.</b></h6><br />
        <!--<form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()">
        <div class=" row">
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="customerName">Customer Name</label>
                    <input formControlName="CustomerName" id="customerName" type="text" class="form-control" placeholder="Enter Your Name">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="contactNumber">Contact Number</label>
                    <input formControlName="ContactNumber" id="contactNumber" type="text" class="form-control" placeholder="+91">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="email">Email ID</label>
                    <input formControlName="EmailID" id="email" type="email" class="form-control" placeholder="Best email to reach you">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="productName">Product Name</label>
                    <input formControlName="R_ProductName" id="productName" type="text" class="form-control" placeholder="Enter Product Name Here">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="specification">Specification (Purity)</label>
                    <input formControlName="Specifications_Purity" id="specification" type="text" class="form-control" placeholder="Enter Specification">
                </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group">
                    <label for="casNo">CAS No</label>
                    <input formControlName="R_CAS_No" id="casNo" type="text" class="form-control" placeholder="Enter Cas No Here">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="file">Attach Specification (COA)</label>
                    <input type="file" id="file" (change)="onFileChange($event)" class="form-control-file">
                </div>
            </div>


            <div class="col-lg-4">
                <div class="form-group">
                    <label for="packSize">Pack Size</label>
                    <input formControlName="R_PackName" id="packSize" type="text" class="form-control" placeholder="Enter Pack Size Here">
                </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group">
                    <label for="quantityRequired">Quantity Required</label>
                    <input formControlName="Qty" id="quantityRequired" type="text" class="form-control" placeholder="Enter Quantity">
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group">
                    <label for="description">Description</label>
                    <textarea formControlName="Description" id="description" class="form-control" placeholder="Enter a description..."></textarea>
                </div>
            </div>
        </div>
        <div class="button_container">
            <button type="submit" class="btn btn-primary" [class.disabled]="!enquiryForm.valid">Submit</button>
        </div>
    </form>-->

        <form form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <label>Customer Name</label>
                        <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Enter customer name">
                        <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                            <div class="fs-7" *ngIf="name.errors.required">Customer Name is required.</div>
                            <div class="fs-7" *ngIf="name.errors.minlength">Customer Name is minimum {{ name.errors.minlength.requiredLength }} characters.</div>
                            <div class="fs-7" *ngIf="name.errors.maxlength">Customer Name is maximum 50 characters.</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label>Phone Number</label>
                        <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Enter phone number">
                        <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                    </div>
                    <div class="col-md-4">
                        <label>Email</label>
                        <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Enter email address">
                        <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                    </div>
                    <div class="col-md-4">
                        <label>Product Name</label>
                        <input required ngModel name="ProductName" type="text" #ProductName="ngModel" class="form-control" id="ProductName" placeholder="Enter product name">
                        <div class="alert alert-danger" *ngIf="ProductName.touched && !ProductName.valid">Product Name is required.</div>
                    </div>
                    <div class="col-md-4">
                        <label>Specification (Purity)</label>
                        <input required ngModel name="Specifications_Purity" type="text" #Specifications_Purity="ngModel" class="form-control" id="Specifications_Purity" placeholder="Enter specifications (purity)">
                        <div class="alert alert-danger" *ngIf="Specifications_Purity.touched && !Specifications_Purity.valid">Specifications Purity is required.</div>
                    </div>
                    <div class="col-md-4">
                        <label>CAS No</label>
                        <input required ngModel name="CAS_No" type="text" #CAS_No="ngModel" class="form-control" id="CAS_No" placeholder="Enter CAS No">
                        <div class="alert alert-danger" *ngIf="CAS_No.touched && !CAS_No.valid">CAS No is required.</div>
                    </div>
                    <div class="col-md-4">
                        <label>Pack Name</label>
                        <input required ngModel name="PackName" type="text" #PackName="ngModel" class="form-control" id="PackName" placeholder="Enter pack name">
                        <div class="alert alert-danger" *ngIf="PackName.touched && !PackName.valid">Pack Name is required.</div>
                    </div>
                    <div class="form_control col-md-4">
                        <label>Quantity</label>
                        <input required ngModel name="Qty" type="text" #Qty="ngModel" class="form-control" id="Qty" placeholder="Enter quantity">
                        <div class="alert alert-danger" *ngIf="Qty.touched && !Qty.valid">Quantity is required.</div>
                    </div>
                    <div class="form_control col-md-4">
                        <label for="Upload">Attach Specification (COA)</label>
                        <div class="m-image-wrapper">
                            <img [attr.src]="image" *ngIf="image" style="cursor:pointer;">
                        </div>
                        <input required ngModel name="Attachment" type="file" (change)="detectFiles(input)" #subject="ngModel" accept="application/doc,pdf,image/*" #input class="m-img-upload-btn" style="font-size: 12px;" placeholder="Attach specification (COA)" />
                    </div>
                    <div class="textarea_control col-md-12">
                        <label>Description</label>
                        <textarea required ngModel #description="ngModel" name="description" id="description" cols="30" rows="4" class="form-control" placeholder="Enter description"></textarea>
                        <div class="alert alert-danger" *ngIf="description.touched && !description.valid">Description is required.</div>
                    </div>
                </div>
            </div>
            <div class="button_container">
                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Submit</button>
            </div>
        </form>
    </div>
</div>
